<template>
	<v-select
		v-model="month"
		:items="months"
		item-text="text"
		item-value="value"
		label="Month"
		:error="error"
		:error-messages="errormessages"
	/>
</template>

<script>
export default {
	props: {
		value: {
			default: 1,
		},
		error: {
			default: false,
		},
		errormessages: {
			default: null,
		},
	},
	data() {
		return {
			month: 1,
			months: [
				{
					text: "January",
					value: 1,
				},
				{
					text: "February",
					value: 2,
				},
				{
					text: "March",
					value: 3,
				},
				{
					text: "April",
					value: 4,
				},
				{
					text: "May",
					value: 5,
				},
				{
					text: "June",
					value: 6,
				},
				{
					text: "July",
					value: 7,
				},
				{
					text: "August",
					value: 8,
				},
				{
					text: "September",
					value: 9,
				},
				{
					text: "October",
					value: 10,
				},
				{
					text: "November",
					value: 11,
				},
				{
					text: "December",
					value: 12,
				},
			],
		};
	},
	mounted() {
		this.month = this.value;
	},
	watch: {
		value(v) {
			this.month = v;
		},
		month(v) {
			this.$emit("input", v);
		},
	},
};
</script>

<style></style>
