<template>
	<div>
		<v-card v-if="loaded" :min-height="minHeight">
			<v-card-title>Linked Cards</v-card-title>
			<v-card-text>
				<center v-if="cards.length < 1">
					<h1>No cards Linked</h1>
				</center>
				<div v-else>
					<v-row dense>
						<v-col cols="2">Default</v-col>
						<v-col></v-col>
						<v-col>Last 4 Digits</v-col>
						<v-col>Expires</v-col>
						<v-col cols="1"></v-col>
					</v-row>
					<v-row
						v-for="i in cards"
						:key="i.id"
						style="font-size:24px"
						align="center"
						dense
					>
						<v-col cols="2">
							<v-checkbox
								:disabled="!!i.DEFAULT_CARD"
								v-model="i.DEFAULT_CARD"
								@change="updateDefaultCards(i)"
							/>
						</v-col>
						<v-col>
							<span v-html="getCardIcon(i.card_brand)" style="font-size:48pt" />
						</v-col>
						<v-col>{{ i.card_last_4 }} </v-col>
						<v-col> {{ i.card_exp_month }}/{{ i.card_exp_year }} </v-col>
						<v-col cols="1">
							<v-btn @click="StartDelete(i)" text fab>
								<v-icon>
									mdi-delete
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn @click="showLinkCard = !showLinkCard" text>Add Card</v-btn>
			</v-card-actions>
		</v-card>
		<v-skeleton-loader type="card" :loading="loaded" v-else>
		</v-skeleton-loader>
		<link
			rel="stylesheet"
			href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
			integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
			crossorigin="anonymous"
			referrerpolicy="no-referrer"
		/>
		<YesNo
			v-model="DeleteConfirm"
			@Result="deleteItem"
			Header="Are you sure you want to delete this card?"
		>
			<div v-if="item">
				Are you sure that you wish to delete the {{ item.card_brand }} card,
				ending in {{ item.card_last_4 }}? This action CANNOT be undone.
			</div>
		</YesNo>
		<LinkACard v-model="showLinkCard" />
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import YesNo from "@/components/ModalMessages/YesNo.vue";
import LinkACard from "./LinkACard.vue";
export default {
	components: { YesNo, LinkACard },
	props: {
		minHeight: {
			default: "300px",
		},
	},
	data() {
		return {
			loaded: false,
			cards: [],
			item: null,
			DeleteConfirm: false,
			showLinkCard: false,
		};
	},
	computed: {
		...mapState(["Organization"]),
	},
	async mounted() {
		this.Load();
	},
	watch: {
		showLinkCard(v) {
			if (!v) {
				this.Load();
			}
		},
	},
	methods: {
		async updateDefaultCards(item) {
			for (let index = 0; index < this.cards.length; index++) {
				const element = this.cards[index];
				element.DEFAULT_CARD = false;
			}
			item.DEFAULT_CARD = true;
			await axios.put(
				`https://api.ticketvm.com/organization/${this.Organization.id}/payments/cards/default/${item.id}`
			);
		},
		async Load() {
			this.loaded = false;
			this.cards = (
				await axios.get(
					`https://api.ticketvm.com/organization/${this.Organization.id}/payments/cards`
				)
			).data;

			this.$nextTick(() => {
				//Hold off setting loaded until the next tick as to not frequently update the database
				this.loaded = true;
			});
		},
		async StartDelete(item) {
			this.item = item;
			this.DeleteConfirm = true;
		},
		async deleteItem(res) {
			this.DeleteConfirm = false;
			if (!res) {
				this.item = null;
				return;
			}
			this.loaded = false;
			await axios.delete(
				`https://api.ticketvm.com/organization/${this.Organization.id}/payments/cards/${this.item.id}`
			);
			this.item = null;
			this.Load();
		},
		getCardIcon(c) {
			switch (c.toLowerCase().replace(/ /g, "")) {
				default:
				case "visa":
					return '<i class="fab fa-cc-visa"></i>';
				case "mastercard":
					return '<i class="fab fa-cc-mastercard"></i>';
				case "americanexpress":
					return '<i class="fab fa-cc-amex"></i>';
				case "dinersclub":
					return '<i class="fab fa-cc-diners-club"></i>';
				case "discover":
					return '<i class="fab fa-cc-discover"></i>';
				case "jcb":
					return '<i class="fab fa-cc-jcb"></i>';
			}
		},
	},
};
</script>

<style></style>
