import axios from "axios";
import moment from "moment";

let f = {
	/**
	 * Gets a report from the reportgen server
	 * @param {string} TemplateURL
	 * @param {object} bodyObj
	 * @param {string} type (optional) PDF, DOCX, ODT
	 */
	// eslint-disable-next-line
	async getReport(TemplateURL, bodyObj, type = "PDF") {
		try {
			let blob = await getTemplate(TemplateURL);
			let URL = "https://reports.shootpro.cloud/";
			// let URL = "https://localhost:9999/";

			let data = new FormData();

			let templateBlob = new Blob([blob]);

			bodyObj.TimeGen = moment().format("MMM Do YYYY h:mm:ss a");
			let sdata = JSON.stringify(bodyObj);
			data.append("data", sdata);
			data.append("convertTo", type);
			data.append("template", templateBlob, "file.docx");

			let config = {
				mode: "no-cors",
				header: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": `multipart/form-data`,
				},
				credentials: "same-origin",
				responseType: "arraybuffer",
			};

			try {
				let response = await axios.post(URL, data, config);
				let blob = new Blob([new Uint8Array(response.data)], {
					type: "application/pdf",
				});
				return blob;
			} catch (error) {
				console.log("error processing report", error);
				if (error.response) console.log(error.response, error.response.body);
				return undefined;
			}
		} catch (error) {
			console.log("error getting template", error);
			if (error.response) console.log(error.response);
			return undefined;
		}
	},

	/**
	 *
	 * @param {string} title - Title of the merged report
	 * @param  {...Blob} files - A list of the files to be used
	 */
	// eslint-disable-next-line
	async mergeReports(title, ...files) {
		try {
			let URL = "https://reports.shootpro.cloud/merge";
			// let URL = "https://localhost:9999/merge";

			let data = new FormData();
			data.append("Title", title);

			// ADD FILES TO BODY
			for (let index = 0; index < files.length; index++) {
				const element = files[index];
				data.append("files", new Blob([element]));
			}

			//CONFIGURE HEADERS
			let config = {
				header: {
					"Content-Type": `multipart/form-data`,
				},
				responseType: "blob",
			};

			//GET REPORT
			try {
				let response = await axios.post(URL, data, config);
				let blob = new Blob([response.data], {
					type: "application/pdf",
				});
				console.log(blob);
				//Creates a blob URL to open in chrome window
				// let URL2 = window.URL.createObjectURL(blob);

				//Call when ready to display report
				// this.$ShowiFrameModal(URL2);
				return blob;
			} catch (error) {
				console.log("error processing report - inner", error);
				if (error.response) console.log(error.response, error.response.body);
				return undefined;
			}
		} catch (error) {
			console.log("error getting template", error);
			if (error.response) console.log(error.response);
			return undefined;
		}
	},
};

async function getTemplate(TemplateURL) {
	return axios({
		url: TemplateURL,
		method: "get",
		responseType: "blob",
		headers: {
			responseType: "blob",
		},
	}).then((response) => response.data);
}

export default f;
