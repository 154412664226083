<template>
	<div>
		<v-row>
			<v-col align="center">
				<v-card>
					<v-card-title>Account Balance</v-card-title>
					<v-card-text class="pb-10">
						<h1 style="font-size:48pt">${{ AccountBal }}</h1>
					</v-card-text>
					<v-card-actions>
						<!-- <v-btn>Auto refill</v-btn> -->
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							@click="showRefill = !showRefill"
							:loading="loadingBalance"
						>
							Refill
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<RefillCard />
			</v-col>
			<v-col>
				<StatementCard />
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<cards />
			</v-col>
			<v-col>
				<billing-alerts />
			</v-col>
		</v-row>
		<Alert
			v-model="showRefill"
			CloseText="Refill my account"
			Header="Refill account balance"
			:persistent="false"
			@Close="refill"
		>
			<v-select
				v-model="refillAmount"
				:items="addToBalanceItems"
				label="Amount to add to account balance"
				item-text="text"
				item-value="value"
			/>
			<span>
				<i>Note: Default card will be used</i>
			</span>
		</Alert>
		<Alert Header="Ooooohhhhh Noooooo" v-model="showError">
			Dang it! We encountered an error. Please try again or contact support.
		</Alert>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import StatementCard from "./components/StatementCard.vue";
import RefillCard from "./components/RefillCard.vue";
import Cards from "./components/Cards.vue";
import BillingAlerts from "./components/BillingAlerts.vue";
import Alert from "@/components/ModalMessages/Alert.vue";
export default {
	components: { StatementCard, RefillCard, Cards, BillingAlerts, Alert },
	data() {
		return {
			showError: false,
			AccountBal: 0,
			showRefill: false,
			refillAmount: 5,
			loadingBalance: false,
			addToBalanceItems: [
				{
					text: "$5.00",
					value: 5,
				},
				{
					text: "$10.00",
					value: 10,
				},
				{
					text: "$15.00",
					value: 15,
				},
				{
					text: "$20.00",
					value: 20,
				},
				{
					text: "$25.00",
					value: 25,
				},
				{
					text: "$30.00",
					value: 30,
				},
				{
					text: "$50.00",
					value: 50,
				},
				{
					text: "$75.00",
					value: 75,
				},
				{
					text: "$100.00",
					value: 100,
				},
				{
					text: "$150.00",
					value: 150,
				},
				{
					text: "$200.00",
					value: 200,
				},
			],
		};
	},
	async mounted() {
		await this.getAccountBalance();
	},
	methods: {
		async getAccountBalance() {
			try {
				this.AccountBal = (
					await axios.get(
						`https://api.ticketvm.com/organization/${this.Organization.id}/payments/balance`
					)
				).data.balance;
			} catch (err) {
				console.log("error getting balance");
				if (err.response) console.log(err.response);
				this.showError = true;
			}
		},
		async refill() {
			this.loadingBalance = true;
			try {
				await axios.post(
					`https://api.ticketvm.com/organization/${this.Organization.id}/payments/fund-account`,
					{
						chargeamount: this.refillAmount,
					}
				);
			} catch (err) {
				console.log("error adding to balance");
				if (err.response) console.log(err.response);
				this.showError = true;
			}
			await this.getAccountBalance();
			this.loadingBalance = false;
		},
	},
	computed: {
		...mapState(["Organization"]),
	},
};
</script>

<style></style>
