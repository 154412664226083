<template>
	<div>
		<v-card v-if="loaded" :min-height="minHeight">
			<v-card-title>Billing Emails</v-card-title>
			<v-card-subtitle>
				These email addresses will recieve notices when account is refilled
			</v-card-subtitle>
			<v-card-text>
				<center v-if="emails.length < 1">
					<h1>No billing emails</h1>
				</center>
				<div v-else>
					<v-row v-for="i in emails" :key="i.email" align="center">
						<v-col>{{ i.name ? i.name : "External Contact" }}</v-col>
						<v-col>{{ i.email }}</v-col>
						<v-col cols="1">
							<v-btn @click="deleteItem(i)" small text fab>
								<v-icon>
									mdi-delete
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer />
				<v-btn @click="showAddBillingEmail = !showAddBillingEmail" text>
					Add Contact
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-skeleton-loader type="card" :loading="loaded" v-else>
		</v-skeleton-loader>

		<YesNo
			v-model="DeleteConfirm"
			@Result="deleteItemConfirmed"
			Header="Delete  contact?"
		>
			<div v-if="deleteObject">
				Are you sure that you wish to stop sending billing emails to
				{{ deleteObject.email }}
			</div>
		</YesNo>
		<Alert
			v-model="showAddBillingEmail"
			Header="Add new billing contact"
			CloseText="Save"
			:BtnDisabled="!formValid"
			:persistent="false"
			@Close="saveContact"
		>
			<v-form v-model="formValid">
				<v-row>
					<v-col>
						<v-text-field
							v-model="form.email"
							label="Email"
							:rules="rules.emailRules"
						/>
					</v-col>
				</v-row>
			</v-form>
		</Alert>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import YesNo from "@/components/ModalMessages/YesNo.vue";
import Alert from "@/components/ModalMessages/Alert.vue";
export default {
	components: { YesNo, Alert },
	props: {
		minHeight: {
			default: "300px",
		},
	},
	data() {
		return {
			emails: [],
			loaded: false,
			DeleteConfirm: false,
			deleteObject: null,
			showAddBillingEmail: false,
			formValid: false,
			rules: {
				emailRules: [
					(v) => !!v || "E-mail is required",
					(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
				],
			},
			form: {
				email: "",
			},
		};
	},
	computed: {
		...mapState(["Organization"]),
	},
	async mounted() {
		this.Load();
	},

	methods: {
		async saveContact() {
			this.loaded = false;
			await axios.post(
				`https://api.ticketvm.com/organization/${this.Organization.id}/payments/emails`,
				this.form
			);
			this.Load();
		},
		async Load() {
			this.loaded = false;
			this.emails = (
				await axios.get(
					`https://api.ticketvm.com/organization/${this.Organization.id}/payments/emails`
				)
			).data;

			this.$nextTick(() => {
				//Hold off setting loaded until the next tick as to not frequently update the database
				this.loaded = true;
			});
		},
		deleteItem(item) {
			this.deleteObject = item;
			this.DeleteConfirm = true;
		},
		async deleteItemConfirmed(res) {
			this.DeleteConfirm = false;
			if (!res) return;
			this.loaded = false;
			await axios.delete(
				`https://api.ticketvm.com/organization/${this.Organization.id}/payments/email/${this.deleteObject.email}`
			);
			this.Load();
			this.deleteObject = null;
		},
	},
};
</script>

<style></style>
