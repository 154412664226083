<template>
	<ScreenDialog v-model="show" @Close="$emit('input', false)">
		<template v-slot:Title>
			<center>
				Link A Card
			</center>
		</template>

		<v-btn
			slot="SaveBtn"
			elevation="0"
			color="primary"
			:loading="saving"
			:disabled="!isValid"
			@click="() => saveCard()"
		>
			Save
		</v-btn>

		<div class="mx-10 mt-5">
			<v-form ref="dform" v-model="isValid">
				<v-row>
					<v-col>
						<v-text-field
							v-cardformat:formatCardNumber
							label="Card Number"
							v-model="cardNumber"
							:error="!!cardErrors.cardNumber"
							:error-messages="cardErrors.cardNumber"
						>
							<template slot="prepend">
								<i :class="cardBrandClass" style="font-size:24pt"></i>
							</template>
						</v-text-field>
					</v-col>
					<v-col cols="2">
						<v-text-field
							v-cardformat:formatCardNumber
							label="CVC"
							v-model="ccv"
							:error="!!cardErrors.cardCvc"
							:error-messages="cardErrors.cardCvc"
						/>
					</v-col>
					<v-col cols="2">
						<month-picker
							v-model="cardExpireMonth"
							:error="!!cardErrors.cardExpiry"
							:errormessages="cardErrors.cardExpiry"
						/>
					</v-col>
					<v-col cols="2">
						<year-picker
							v-model="cardExpireYear"
							:endYear="new Date().getFullYear() + 50"
							:noReverse="true"
							:error="!!cardErrors.cardExpiry"
							:errormessages="cardErrors.cardExpiry"
						/>
					</v-col>
				</v-row>

				<v-row>
					<v-col>
						<v-text-field
							v-model="BillingName"
							label="Billing Name"
							hint="What is your name?"
							:rules="rules.required"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field
							v-model="Add1"
							label="Address 1"
							hint="What is the street address of your business."
							:rules="rules.required"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field
							v-model="Add2"
							label="Suite# (optional)"
							hint="What is the second line of your address? If applicable"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field
							v-model="City"
							label="City"
							:rules="rules.required"
							hint="What is the city your business is based in?"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-autocomplete
							v-model="State_full"
							label="Please select a state"
							:items="States"
							:rules="rules.required"
							item-text="name"
							hide-no-data
							return-object
						/>
					</v-col>
					<v-col>
						<v-autocomplete
							v-model="Zip"
							label="Please select a Zipcode"
							:items="State_full ? State_full.zips : []"
							:rules="rules.required"
							@input="State = State_full.abbreviation"
							hide-no-data
						/>
					</v-col>
				</v-row>
			</v-form>
		</div>
		<link
			rel="stylesheet"
			href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
			integrity="sha512-iBBXm8fW90+nuLcSKlbmrPcLa0OT92xO1BIsZ+ywDWZCvqsWgccV3gFoRBv0z+8dLJgyAHIhR35VZc2oM/gI1w=="
			crossorigin="anonymous"
			referrerpolicy="no-referrer"
		/>
	</ScreenDialog>
</template>

<script>
import ScreenDialog from "@/components/ModalMessages/ScreenDialog.vue";
import MonthPicker from "@/components/MonthPicker.vue";
import YearPicker from "@/components/YearPicker.vue";
import { mapState } from "vuex";
import axios from "axios";
export default {
	props: {
		value: {
			default: false,
		},
	},
	components: { ScreenDialog, MonthPicker, YearPicker },
	data() {
		return {
			saving: false,
			show: false,
			cardNumber: "",
			ccv: "",
			cardExpireMonth: 0,
			cardExpireYear: 0,
			BillingName: "",
			Add1: "",
			Add2: "",
			City: "",
			State: "",
			Zip: "",
			States: [],
			State_full: null,
			isValid: false,
			cardBrand: null,
			cardErrors: {
				cardNumber: null,
				cardExpiry: null,
				cardCvc: null,
			},
			rules: {
				required: [(v) => !!v || "Required"],
				min: [(v) => v.length >= 8 || "Min 8 characters"],
				emailRules: [
					(v) => !!v || "E-mail is required",
					(v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
				],
				min10: (value) =>
					(value || "").length >= 14 || "10 digit phone number is required",
			},
		};
	},
	created() {
		this.States = require("@/assets/USStates.json");
	},
	mounted() {
		this.show = this.value;
		this.Add1 = this.$store.state.Organization.Address1;
		this.Add2 = this.$store.state.Organization.Address2;
		this.City = this.$store.state.Organization.City;
		this.State = this.$store.state.Organization.State;
		this.Zip = this.$store.state.Organization.Zip;
		this.State_full = this.States.filter(
			(x) => x.abbreviation == this.State
		)[0];
	},
	methods: {
		validate() {
			this.cardErrors = {
				cardNumber: null,
				cardExpiry: null,
				cardCvc: null,
			};

			if (this.cardNumber) {
				if (!this.$cardFormat.validateCardNumber(this.cardNumber)) {
					this.cardErrors.cardNumber = "Invalid Credit Card Number.";
				}
			}

			if (this.cardExpireMonth >= 1 && this.cardExpireYear >= 1) {
				let cardExpiry = `${
					this.cardExpireMonth
				}/${this.cardExpireYear.toString().substring(2, 4)}`;
				console.log(cardExpiry);
				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(cardExpiry)) {
					this.cardErrors.cardExpiry = "Invalid Expiration Date.";
				}
			}

			if (this.ccv) {
				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.ccv)) {
					this.cardErrors.cardCvc = "Invalid CVC.";
				}
			}
		},
		getBrandClass: function(brand) {
			let icon = "";
			brand = brand || "unknown";
			let cardBrandToClass = {
				visa: "fab fa-cc-visa",
				mastercard: "fab fa-cc-mastercard",
				amex: "fab fa-cc-amex",
				discover: "fab fa-cc-discover",
				diners: "fab fa-cc-diners-club",
				jcb: "fab fa-cc-jcb",
				unknown: "fa fa-credit-card",
			};
			if (cardBrandToClass[brand]) {
				icon = cardBrandToClass[brand];
			} else {
				icon = cardBrandToClass.unknown;
			}

			return icon;
		},
		async saveCard() {
			this.saving = true;
			try {
				let res = await axios.post(
					`https://api.ticketvm.com/organization/${this.Organization.id}/payments/link-card`,
					{
						number: this.cardNumber,
						exp_month: this.cardExpireMonth,
						exp_year: this.cardExpireYear,
						cvc: this.ccv,
						name: this.BillingName,
						address_line1: this.Add1,
						address_line2: this.Add2,
						address_city: this.City,
						address_state: this.State,
						address_zip: this.Zip,
					}
				);
				res;
			} catch (err) {
				console.log(err);
				if (err.response) console.log(err.response);
			}
			this.saving = false;
			this.$emit("input", false);
		},
	},
	watch: {
		cardNumber() {
			this.validate();
		},
		ccv() {
			this.validate();
		},
		cardExpireYear() {
			this.validate();
		},
		cardExpireMonth() {
			this.validate();
		},
		value(v) {
			this.show = v;
		},
		State_full(v) {
			this.State = v.abbreviation;
		},
		isValid(v) {
			this.$emit("isValid", v);
			if (!v) return;
			this.$emit("input", {
				Title: this.Titl,
				Add1: this.Add1,
				Add2: this.Add2,
				City: this.City,
				State: this.State,
				Zip: this.Zip,
			});
		},
		getCardIcon(c) {
			switch (c.toLowerCase().replace(/ /g, "")) {
				default:
				case "visa":
					return '<i class="fab fa-cc-visa"></i>';
				case "mastercard":
					return '<i class="fab fa-cc-mastercard"></i>';
				case "americanexpress":
					return '<i class="fab fa-cc-amex"></i>';
				case "dinersclub":
					return '<i class="fab fa-cc-diners-club"></i>';
				case "discover":
					return '<i class="fab fa-cc-discover"></i>';
				case "jcb":
					return '<i class="fab fa-cc-jcb"></i>';
			}
		},
	},
	computed: {
		...mapState(["Organization"]),
		cardBrandClass() {
			return this.getBrandClass(this.cardBrand);
		},
	},
};
</script>

<style></style>
