<template>
	<div>
		<v-card v-if="statementSummaryLoaded" :min-height="minHeight">
			<v-card-title>
				Statement Summary
			</v-card-title>
			<v-card-text>
				<v-row align="center">
					<v-col>
						<v-divider />
					</v-col>
					<v-col cols="3">
						<MonthPicker v-model="month" />
					</v-col>
					<v-col cols="3">
						<YearPicker v-model="year" :startYear="2020" />
					</v-col>
				</v-row>
				<v-row v-if="statementSummary.length == 0">
					<v-col align="center">
						<h1>There are no transactions this month.</h1>
					</v-col>
				</v-row>
				<v-row v-else v-for="i in statementSummary" :key="i.type" dense>
					<v-col align="end">
						<h3>{{ i.type }}</h3>
					</v-col>
					<v-col>
						<h3>${{ parseFloat(i.amount).toFixed(2) }}</h3>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions v-if="statementSummary.length != 0">
				<v-spacer />
				<v-btn :loading="gettingStatement" text @click="getPDFStatement">
					Get PDF Statement
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-skeleton-loader type="card" :loading="statementSummaryLoaded" v-else>
		</v-skeleton-loader>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import fileDownload from "js-file-download";
import getreport from "@/assets/getreport";
import MonthPicker from "@/components/MonthPicker.vue";
import YearPicker from "@/components/YearPicker.vue";
export default {
	components: { MonthPicker, YearPicker },
	data() {
		return {
			month: 1,
			year: 2021,
			statementSummary: null,
			gettingStatement: false,
		};
	},
	mounted() {
		var d = new Date();
		this.month = d.getMonth() + 1;
		this.year = d.getFullYear();
		//Default to last month
		if (this.month > 1) {
			this.month--;
		} else if (this.month == 1) {
			this.month = 12;
			this.year--;
		}
	},
	props: {
		minHeight: {
			default: "300px",
		},
	},
	watch: {
		month() {
			this.statementSummary = null;
			this.UpdateSummaryStatement();
		},
		year() {
			this.statementSummary = null;
			this.UpdateSummaryStatement();
		},
	},
	computed: {
		...mapState(["Organization"]),

		statementSummaryLoaded() {
			// eslint-disable-next-line
			return !!this.statementSummary;
		},
	},
	methods: {
		async UpdateSummaryStatement() {
			if (!this.Organization) return;
			this.statementSummary = (
				await axios.get(
					`https://api.ticketvm.com/organization/${this.Organization.id}/payments/statement/${this.month}/${this.year}`
				)
			).data;
		},
		async getPDFStatement() {
			this.gettingStatement = true;
			let Data = (
				await axios.get(
					`https://api.ticketvm.com/organization/${this.Organization.id}/payments/statement/full/${this.month}/${this.year}/yes`
				)
			).data;
			let res = await getreport.getReport("/AccountStatement.docx", Data);
			fileDownload(res, `TicketVM Statement - ${this.month}/${this.year}.pdf`);
			this.gettingStatement = false;
		},
	},
};
</script>

<style></style>
