<template>
	<v-select
		v-model="year"
		:items="years"
		item-text="text"
		item-value="value"
		label="Year"
		:error="error"
		:error-messages="errormessages"
	/>
</template>

<script>
export default {
	props: {
		value: {
			default: 2021,
		},
		startYear: {
			default: 2021,
		},
		endYear: {
			default: new Date().getFullYear(),
		},
		noReverse: {
			default: false,
		},
		error: {
			default: false,
		},
		errormessages: {
			default: null,
		},
	},
	data() {
		return {
			year: 2021,
		};
	},
	watch: {
		value(v) {
			this.month = v;
		},
		year(v) {
			this.$emit("input", v);
		},
	},
	mounted() {
		this.year = this.value;
	},
	computed: {
		years() {
			let Years = [];
			for (let index = this.startYear; index <= this.endYear; index++) {
				Years.push({
					text: `${index}`,
					value: index,
				});
			}
			if (!this.noReverse) Years.reverse();
			return Years;
		},
	},
};
</script>

<style></style>
