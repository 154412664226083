<template>
	<div>
		<v-card v-if="loaded" :min-height="minHeight">
			<v-card-title>Auto Refill Settings</v-card-title>
			<v-card-text>
				<v-row align="center">
					<v-col cols="4" align="end">
						<b>Refill When Below</b>
					</v-col>
					<v-col>
						<v-select
							v-model="whenBelow"
							:items="whenBelowItems"
							:loading="updating"
							item-text="text"
							item-value="value"
						/>
					</v-col>
				</v-row>
				<v-row align="center" dense>
					<v-col cols="4" align="end">
						<b>Add to account</b>
					</v-col>
					<v-col>
						<v-select
							v-model="addToBalance"
							:items="addToBalanceItems"
							:loading="updating"
							item-text="text"
							item-value="value"
						/>
					</v-col>
				</v-row>
				<i>
					Note: If you have more users than the refill threshold, your card will
					be charged based on the number of users you have.
				</i>
			</v-card-text>
		</v-card>
		<v-skeleton-loader type="card" :loading="loaded" v-else>
		</v-skeleton-loader>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
	props: {
		minHeight: {
			default: "300px",
		},
	},
	data() {
		return {
			loaded: false,
			updating: false,
			whenBelow: 5,
			whenBelowItems: [
				{
					text: "$5.00",
					value: 5,
				},
				{
					text: "$10.00",
					value: 10,
				},
				{
					text: "$15.00",
					value: 15,
				},
				{
					text: "$20.00",
					value: 20,
				},
				{
					text: "$25.00",
					value: 25,
				},
			],
			addToBalance: 10,
			addToBalanceItems: [
				{
					text: "$10.00",
					value: 10,
				},
				{
					text: "$15.00",
					value: 15,
				},
				{
					text: "$20.00",
					value: 20,
				},
				{
					text: "$25.00",
					value: 25,
				},
				{
					text: "$30.00",
					value: 30,
				},
				{
					text: "$50.00",
					value: 50,
				},
				{
					text: "$75.00",
					value: 75,
				},
				{
					text: "$100.00",
					value: 100,
				},
				{
					text: "$150.00",
					value: 150,
				},
				{
					text: "$200.00",
					value: 200,
				},
			],
		};
	},
	computed: {
		...mapState(["Organization"]),
	},
	async mounted() {
		let data = (
			await axios.get(
				`https://api.ticketvm.com/organization/${this.Organization.id}/payments/funding`
			)
		).data;

		this.whenBelow = parseFloat(data.FundWhenBelow);
		this.addToBalance = parseFloat(data.FundAmount);
		this.$nextTick(() => {
			//Hold off setting loaded until the next tick as to not frequently update the database
			this.loaded = true;
		});
	},
	watch: {
		whenBelow(v) {
			if (!v || !this.loaded) return;
			this.UpdateRefillSettings();
		},
		addToBalance(v) {
			if (!v || !this.loaded) return;
			this.UpdateRefillSettings();
		},
	},
	methods: {
		async UpdateRefillSettings() {
			this.updating = true;
			axios
				.put(
					`https://api.ticketvm.com/organization/${this.Organization.id}/payments/funding`,
					{
						FundAmount: this.addToBalance,
						FundWhenBelow: this.whenBelow,
					}
				)
				.then(() => {
					this.updating = false;
				})
				.catch((err) => {
					console.log(err);
					if (err.response) console.log(err.response);
				});
		},
	},
};
</script>

<style></style>
