<template>
	<v-row justify="center">
		<v-dialog
			v-model="show"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card height="50%">
				<v-toolbar dark color="#303030">
					<v-btn icon dark @click="CloseModal">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>
						<!-- @slot This is where you place your text for your title -->
						<slot name="Title"></slot>
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-toolbar-items>
						<!-- @slot This is where you place a save button if you want it -->
						<slot name="SaveBtn"></slot>
					</v-toolbar-items>
				</v-toolbar>

				<!-- @slot This is where content is rendered -->
				<slot></slot>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	props: {
		/**
		 * Controls if the dialog is visible
		 */
		dialog: {
			default: false,
		},

		value: {
			default: false,
		},
	},
	data() {
		return {
			show: false,
		};
	},
	mounted() {
		this.show = this.dialog || this.value;
	},
	watch: {
		dialog(v) {
			this.show = v || this.value;
		},
		value(v) {
			this.show = this.dialog || v;
		},
	},
	methods: {
		CloseModal() {
			this.$emit("Close");
			this.$emit("input", false);
		},
	},
};
</script>

<style></style>
